<template>
  <div class="homePage">
    <!--    <div class="notice-box">-->
    <!--      <div class="notice-left">-->
    <!--        <div class="notice-left-title">公示公告</div>-->
    <!--        <div>-->
    <!--          <div class="notice-item" v-for="item in 2" :key="item">-->
    <!--            <div class="dot"></div>-->
    <!--            <div class="notice-content">-->
    <!--              12月8日城市无新增本地新冠肺炎确诊病例，新增4-->
    <!--            </div>-->
    <!--            <div class="notice-time">2021·06·21</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="notice-right">-->
    <!--        <div>-->
    <!--          <div class="notice-item" v-for="item in 2" :key="item">-->
    <!--            <div class="dot"></div>-->
    <!--            <div class="notice-content">-->
    <!--              12月8日城市无新增本地新冠肺炎确诊病例，新增4-->
    <!--            </div>-->
    <!--            <div class="notice-time">2021·06·21</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="notice-right-more">更多</div>-->
    <!--      </div>-->
    <!--    </div>-->
    <el-carousel
      indicator-position="none"
      arrow="never"
      height="60px"
      direction="vertical"
      style="padding: 0 45px"
    >
      <el-carousel-item v-for="(item, index) of topNewsList.slice(0,3)" :key="index">
        <div class="top-carousel-title" @click="toPage('NewsDetail',{
                from: encodeURI(item.newsTypeList[0].newsTypeName),
                sliderChildrenIndex: -1,
                id:item.id,
                type:item.newsTypeList[0].newsTypeId})">
          {{ item.title.split('/n').join('') }}
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="content-1">
      <div class="content-1-left">
        <el-carousel
          height="517px"
          indicator-position="none"
          arrow="never"
          class="left-swiper-box"
          @change="swiperChange"
          ref="mySwiper"
          @mouseenter.native="swiperMouseEnter"
          @mouseleave.native="swiperMouseLeave"
        >
          <el-carousel-item
            v-for="(item, index) of headerSwiperList"
            :key="index"
          >
            <img @click="lookSwiperDetail(item)" :src="item.image" style="width: 100%; height: 100%" />
          </el-carousel-item>
          <div class="swiper-dot-des">
            <div class="swiper-des">
              {{ headerSwiperList[swiperActive].bannerName }}
            </div>
            <div class="swiper-dot-box">
              <div
                class="swiper-dot-item"
                @click="dotClick(index)"
                :class="{ active: swiperActive === index }"
                v-for="(item, index) of headerSwiperList"
                :key="index"
              ></div>
            </div>
          </div>
          <div class="left-arrow" v-if="showArrow" @click="swiperLeft"></div>
          <div class="right-arrow" v-if="showArrow" @click="swiperRight"></div>
        </el-carousel>
      </div>
      <div class="content-1-right" >
        <div class="content-1-right-catalog">
          <div
            class="catalog-item"
            :class="{ active: catalogType === 'news' }"
            @mouseenter="catalogType = 'news'"
            @click="cataLogClick('news')"
          >
            时政要闻
          </div>
          <div
            class="catalog-item"
            :class="{ active: catalogType === 'dynamic' }"
            @mouseenter="catalogType = 'dynamic'"
            @click="cataLogClick('dynamic')"
          >
            工会动态
          </div>
        </div>
        <div class="content-1-newsBox" v-if="topNewsList.length > 0">
          <div
            class="news-item"
            v-for="(item, index) in topNewsList.slice(0,8)"
            :key="index"
            @click="toPage('NewsDetail',{
                from: encodeURI(item.newsTypeList[0].newsTypeName),
                sliderChildrenIndex: -1,
                id:item.id,
                type:item.newsTypeList[0].newsTypeId})"
          >
            <div style="display: flex; align-items: center">
              <div class="news-item-dot"></div>
              <div class="news-item-title">{{ item.title.split('/n').join('') }}</div>
            </div>

            <div class="news-item-time">
              {{ item.createTime.split(" ")[0] }}
            </div>
          </div>
        </div>
        <Empty v-else style="flex: 1;min-width: 510px"></Empty>
      </div>

    </div>
    <div class="content-2-swiper">
      <el-carousel
        height="150px"
        indicator-position="none"
        arrow="never"
        @change="contentSwiperChange"
        ref="contentSwiper"
      >
        <el-carousel-item v-for="(item,index) in loinSwiperList" :key="index">
          <img :src="item.image" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
      <div class="content-2-swiper-dot-box">
        <div
          class="content-2-swiper-dot-box-item"
          @click="contentDotClick(index)"
          :class="{ active: swiperActive2 === index }"
          v-for="(item, index) in loinSwiperList"
          :key="index"
        ></div>
      </div>
    </div>
    <div class="content-3">
      <div class="content-3-left">
        <div class="newsTab-box">
          <div
            class="newsTab"
            :class="{ active: newsTbaActive === item.label }"
            @click="chooseNewsTab(item)"
            v-for="(item, index) of newsTab"
            :key="index"
          >
            {{ item.label }}
          </div>
          <div class="newsTba-more" @click="toPage('NewsTrend')">更多>>></div>
        </div>
        <div class="news-box" v-if="content_three_newsList.length !== 0">
          <div class="news-box-item" v-for="(item,index) in content_three_newsList.slice(0,5)" :key="index" @click="content_t_detail(item)">
            <div class="news-title">
              <div class="news-dot"></div>
              <span class="news-des"
                >{{item.title.split('/n').join('')}}</span
              >
            </div>
            <div class="news-time">{{ item.createTime.split(" ")[0] }}</div>
          </div>
        </div>
        <Empty v-else></Empty>
      </div>
      <div class="content-3-right">
        <div class="content-title-box">
          <div class="content-title">公示公告</div>
          <div class="content-more" @click="toPage('OpenInfo')">更多>>></div>
        </div>
        <div class="news-box" v-if="noticeList.length > 0">
          <div class="news-box-item" v-for="(item,index) in noticeList.slice(0,5)" :key="index" @click="content_t_detail(item)">
            <div class="news-title">
              <div class="news-dot"></div>
              <span class="news-des" style="width: 384px"
                >{{item.title.split('/n').join('')}}</span
              >
            </div>
            <div class="news-time">{{item.createTime.split(' ')[0]}}</div>
          </div>
        </div>
        <Empty v-else></Empty>
      </div>
    </div>
    <div class="service-center">
      <div class="content-title-box">
        <div class="content-title">服务大厅</div>
        <div class="content-more" @click="toPage('ServiceCenter')">更多>>></div>
      </div>
      <div class="service-content">
        <div class="service-content-list">
          <div
            class="content-list-item"
            :class="{ active: item.title === activeService.title }"
            v-for="(item, index) of serviceList"
            :key="index"
            @click="chooseService(item)"
          >
            <span>{{ item.title }}</span>
            <img src="@/assets/image/common/arrowRight-icon.png" />
          </div>
        </div>
        <div class="content-children-list">
          <img
            :src="item.bgImg"
            v-for="(item, index) of activeService.list"
            :key="index"
            @click="toServiceCenterList(item)"
          />
        </div>
      </div>
    </div>
    <div class="content-4">
      <div class="content-4-left">
        <div class="content-title-box">
          <div class="content-title">工会视频</div>
          <div class="content-more" @click="toVideo">更多>>></div>
        </div>
        <div class="video-list-box">
          <div
            class="video-list-item"
            v-for="(item,index) in videoList.slice(0,2)"
            :key="index"
            @click="toPage('unionVideoDetail', { id:item.id })"
          >
            <img style="width:100%;height: 100%" :src="item.image">
            <img class="pause-icon" src="@/assets/image/common/pause.png" />
            <div class="video-des">
              <span
                >{{item.title}}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="content-4-right">
        <div style="margin-right: 36px; flex: 1">
          <div class="content-title-box" style="margin-bottom: 62px">
            <div class="content-title">互动交流</div>
          </div>
          <div class="content-4-right-content" @click="toLeaderEmail">
            <img
              class="content-4-right-content-icon"
              src="@/assets/image/home/leader-email-icon.png"
            />
            领导信箱
          </div>
          <div
            class="content-4-right-content"
            @click="toPage('QuestionnaireSurvey')"
          >
            <img
              class="content-4-right-content-icon"
              src="@/assets/image/home/questionnaire-icon.png"
            />
            问卷调查
          </div>
        </div>
        <div style="flex: 1">
          <div class="content-title-box" style="margin-bottom: 62px">
            <div class="content-title">文件资料</div>
          </div>
          <div class="content-4-right-content" @click="toPolicy">
            <img
              class="content-4-right-content-icon"
              src="@/assets/image/home/file-icon.png"
            />
            政策文件
          </div>
          <div class="content-4-right-content" @click="toLegislation">
            <img
              class="content-4-right-content-icon"
              src="@/assets/image/home/law-icon.png"
            />
            法律法规
          </div>
        </div>
      </div>
    </div>
    <div class="special-column-box">
      <img
        class="special-column-logo"
        src="@/assets/image/home/special-column-icon.png"
      />
      <swiper class="swiper" :options="swiperOption" ref="homeSwiper">
        <swiper-slide v-for="(item,index) in topicList" :key="index">
          <img :src="item.image" :data-name="item.name" :data-id="item.id" style="width: 270px" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="friendly-link-box">
      <div class="content-title-box">
        <div class="content-title">友情链接</div>
      </div>
      <div class="friendly-link-content">
        <img
          class="link-img"
          :src="item.image"
          v-for="(item,index) in friendlyLink"
          :key="index"
          @click="linkClick(item)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import scrollTop from "@/components/scrollTop.vue";
import "swiper/dist/css/swiper.css";
import { getBanner, getNewsList, getVideoList, getTopicList, linkList, basicsInfo } from "@/api";
import Empty from "@/components/Empty.vue";
let vm = null;
export default {
  components: {
    Empty,
    Swiper,
    SwiperSlide,
    scrollTop,
  },
  data() {
    return {
      headerSwiperList: [{bannerName:''}],
      loinSwiperList:[],
      swiperActive: 0,
      swiperActive2: 0,
      showArrow: false,
      catalogType: "news",
      newsTab: [
        { label: "劳模工匠",value:12 },
        { label: "部门动态",value:4 },
        { label: "机关党建",value:13 },
        { label: "基层动态",value:3 },
      ],
      newsTbaActive: "劳模工匠",
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 21,
        autoplay: true,
        loop: true,
        on:{
          click: function(swiper){
            const data = swiper.target.dataset
            if(data.name) {
              vm.$router.push({
                name: "thematicCollectionList",
                query: { from: encodeURI(data.name),id:data.id },
              });
            }

          },
        }
      },
      serviceList: [],
      activeService: {},
      newsParams: {
        current: 1,
        size: 10,
        type: 1, //1.时政要闻 2.工会动态
      },
      topNewsList: [],
      content_three_newsList:[],
      videoList:[],
      topicList:[],
      friendlyLink:[],
      noticeList:[]
    };
  },
  watch: {
    async catalogType(val,oldVal) {
      if (val === 'news') {
        this.newsParams.type = 1
        this.topNewsList = await this.getNewsList()
      } else {
        this.newsParams.type = 2
        this.topNewsList = await this.getNewsList()
      }
    }
  },
  created() {
    vm = this;
  },
  async mounted() {
    this.serviceList = this.$store.state.serviceCenterList.slice(0, 3);
    this.activeService = this.serviceList[0];
    this.headerSwiperList = await this.getBanner(0)
    this.topNewsList = await this.getNewsList()
    this.loinSwiperList = await this.getBanner(1)
    this.newsParams.type = 12
    this.content_three_newsList = await this.getNewsList()
    this.newsParams.type = 14
    this.noticeList = await this.getNewsList()
    getVideoList().then(res=>{
      if (res.code === 200) {
        this.videoList = res.data.records
      }
    })
    this.getTopic()
    this.getLinkList()
  },
  methods: {
    toServiceCenterList(item) {
      const treeList = JSON.parse(localStorage.getItem('treeList'))
      const fatherArr = ['法律维权','基层建会','困难职工帮扶']
      const childList = []
      treeList.forEach(v=>{
        if (fatherArr.includes(v.name)){
          childList.push(...v.childrenList)
        }
      })
      const clickType = childList.find(v=>v.name === item.label)
      this.$router.push({name:"ServiceCenterList",query:{
          type:clickType.id,
          pageName:encodeURI(clickType.name)
        }})
    },
    linkClick(item) {
      window.open(item.linkValue,"_blank")
    },
    getLinkList() {
      linkList().then(res=>{
        this.friendlyLink = res.data
      })
    },
    getTopic() {
      getTopicList().then(res=>{
        if (res.code === 200) {
          if (res.data.records.length <= 5) {
            this.swiperOption.loop = false
          }
          this.topicList = res.data.records
        }
      })
    },
    content_t_detail(item){

      if (item.newsTypeList[0].newsTypeId >= 1 && item.newsTypeList[0].newsTypeId <= 12) {
        const childTypeArr = [5,6,7,8,9,10,11]
        const checkIsChild = childTypeArr.findIndex(i=>i === item.newsTypeList[0].newsTypeId)

        if (checkIsChild !== -1) {
          this.$router.push({
            name: "NewsDetail",
            query: {
              from: encodeURI(item.newsTypeList[0].newsTypeName),
              sliderChildrenIndex: checkIsChild,
              id:item.id,
              type:item.newsTypeList[0].newsTypeId
            },
          });
        } else {
          this.$router.push({
            name: "NewsDetail",
            query: {
              from: encodeURI(item.newsTypeList[0].newsTypeName),
              sliderChildrenIndex: -1,
              id:item.id,
              type:item.newsTypeList[0].newsTypeId
            },
          });
        }
      }

      if (item.newsTypeList[0].newsTypeId === 13) {
        this.$router.push({
          name: "partyBuildingDetail",
          query: {
            // from: encodeURI(item.typeName),
            // url: encodeURI("/PartyBuilding"),
            // type:item.type,
            // id:item.id,
            from: encodeURI(item.newsTypeList[0].newsTypeName),
            id:item.id,
            type:item.newsTypeList[0].newsTypeId,
            url: encodeURI("/PartyBuilding")
          },
        });
      }

      if (item.newsTypeList[0].newsTypeId >=14 && item.newsTypeList[0].newsTypeId<=16) {
        this.$router.push({
          name: "OpenInfoDetail",
          query: {
            from: encodeURI(item.newsTypeList[0].newsTypeName),
            id:item.id,
            type:item.newsTypeList[0].newsTypeId,
          },
        });
      }
    },
    lookSwiperDetail(item) {
      if (item.bannerLink) {
        window.open(item.bannerLink, "_blank");
      }
    },
    chooseService(item) {
      this.activeService = item;
    },
    toPage(pageName, query = undefined) {
      if (query) {
        this.$router.push({
          name: pageName,
          query: query,
        });
      } else {
        if (pageName === "NewsTrend") {
          this.$router.push({
            name: pageName,
            query: { sliderChildrenIndex: -1 },
          });
        } else {
          this.$router.push({ name: pageName });
        }
      }
    },
    toLegislation() {
      localStorage.setItem("activeTab", "OpenInfo");
      this.$router.push({
        name: "OpenInfo",
        query: { pageType: encodeURI("法律法规") },
      });
    },
    toPolicy() {
      localStorage.setItem("activeTab", "OpenInfo");
      this.$router.push({
        name: "OpenInfo",
        query: { pageType: encodeURI("政策文件") },
      });
    },
    toLeaderEmail() {
      this.$router.push({ name: "LeaderEmail" });
    },
    toVideo() {
      this.$router.push({ name: "UnionVideo" });
    },
    async chooseNewsTab(item) {
      this.newsTbaActive = item.label;
      this.newsParams.type = item.value
      this.content_three_newsList = await this.getNewsList()
    },
    contentSwiperChange(e) {
      this.swiperActive2 = e;
    },
    swiperChange(e) {
      this.swiperActive = e;
    },
    swiperLeft() {
      this.$refs.mySwiper.prev();
    },
    swiperRight() {
      this.$refs.mySwiper.next();
    },
    dotClick(index) {
      this.$refs.mySwiper.setActiveItem(index);
    },
    contentDotClick(index) {
      this.$refs.contentSwiper.setActiveItem(index);
    },
    swiperMouseEnter() {
      this.showArrow = true;
    },
    swiperMouseLeave() {
      this.showArrow = false;
    },
    async cataLogClick(type) {
      this.catalogType = type;
      if (type === 'news') {
        this.toPage('NewsTrend',{sliderChildrenIndex: -1,pageType:encodeURI('时政要闻')})
      } else {
        this.toPage('NewsTrend',{sliderChildrenIndex: -1,pageType:encodeURI('工会动态')})
      }
    },
    getNewsList() {
      return new Promise((resolve, reject)=>{
        getNewsList(this.newsParams).then((res) => {
          if (res.code === 200) {
            resolve(res.data.records.slice(0,8))
          }
        });
      })
    },
    getBanner(type) {
      //0 头部 1腰部
      return new Promise((resolve,reject)=>{
        getBanner({ bannerType: type}).then((res) => {
          resolve(res.data)
        });
      })

    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";
.swiper {
  height: 87px;
  width: 100%;
  .swiper-slide {
    cursor: pointer;
  }
}

.homePage {
  padding: 0 $pagePadding;
  margin-top: 50px;
  min-width: $minPageWidth;
  box-sizing: border-box;
  //@media screen and (max-width: 1670px) {
  //  padding: 0 119px;
  //}
  .top-carousel-title {
    @include ellipsis();
    color: #bf0302;
    font-size: 42px;
    font-weight: bold;
    cursor: pointer;
    height: 100%;
  }
  .notice-box {
    height: 129px;
    background: #fef5f6;
    padding: 23px 30px;
    box-sizing: border-box;
    @include flex(cener, space-between);

    .notice-left,
    .notice-right {
      @include flex-center();

      .notice-left-title {
        width: 94px;
        height: 84px;
        background: url("@/assets/image/home/notice-bg.png");
        background-size: cover;
        box-sizing: border-box;
        font-size: 22px;
        color: #fff;
        padding: 12px 21px;
        margin-right: 47px;
      }

      .notice-item {
        font-size: 16px;
        font-weight: bold;
        color: #837d7d;
        @include flex-center();
        margin-bottom: 15px;
        cursor: pointer;

        &:hover {
          color: #8c5b5f;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #562c2e;
          margin-right: 12px;
        }

        .notice-time {
          color: #89696c;
          margin-left: 27px;
          font-weight: 400;
        }
      }

      .notice-right-more {
        width: 29px;
        height: 58px;
        background: #c20000;
        border-radius: 15px;
        font-size: 16px;
        color: #ffd6d4;
        text-align: center;
        @include flex-center();
        margin-left: 43px;
        cursor: pointer;
      }
    }
  }

  .content-1 {
    margin-top: 48px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    .content-1-left {
      width: 80%;
      margin-right: 20px;
      background: #c20000;

      .left-swiper-box {
        position: relative;

        .swiper-dot-des {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 34px;
          //padding-right: 9px;
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          height: 66px;
          z-index: 99;
          background: rgba(4, 3, 3, 0.6);

          .swiper-des {
            width: 550px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: nowrap;
            color: #fff;
            font-size: 20px;
          }

          .swiper-dot-box {
            display: flex;
            align-items: center;
            z-index: 9999;
            //flex: 1;

            .swiper-dot-item {
              width: 19px;
              height: 19px;
              background: #ccc;

              margin-right: 9px;
              border-radius: 50%;
              cursor: pointer;

              &.active {
                background: #ff0000;
              }
            }
          }
        }

        .left-arrow {
          width: 87px;
          height: 87px;
          background: url("@/assets/image/home/leftArrow.png");
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 29px;
          transform: translateY(-50%);
          z-index: 9999;
          cursor: pointer;

          &:hover {
            background: url("@/assets/image/home/leftArrow-active.png");
            background-size: cover;
          }
        }

        .right-arrow {
          width: 87px;
          height: 87px;
          background: url("@/assets/image/home/rightArrow.png");
          background-size: cover;
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translateY(-50%);
          z-index: 9999;
          cursor: pointer;

          &:hover {
            background: url("@/assets/image/home/rightArrow-active.png");
            background-size: cover;
          }
        }
      }
    }

    .content-1-right {
      flex: 1;
      display: flex;
      align-items: flex-start;
      height: 517px;

      .content-1-right-catalog {
        margin-right: 32px;

        .catalog-item {
          width: 43px;
          height: 257px;
          background: #fef5f6;
          border-radius: 4px;
          color: #8c5b5f;
          font-size: 22px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          padding-left: 10px;
          cursor: pointer;

          &.active {
            width: 33px;
            padding: 0 10px;
            box-sizing: content-box;
            color: #ffd6d4;
            background: url("@/assets/image/home/catalog.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }

      .content-1-newsBox {
        flex: 1;

        .news-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 18px;
          color: #686868;
          padding: 27px 0 16px 0;
          border-bottom: 1px solid #f2f2f2;
          flex: 1;

          &:first-child {
            padding-top: 16px;
          }

          &:last-child {
            border-bottom: none;
          }

          .news-item-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #916640;
            margin-right: 9px;
          }

          .news-item-title {
            @include ellipsis(400px);
          }

          .news-item-title:hover {
            color: #bf0302;
            cursor: pointer;
          }
          .news-item-time {
            width: 102px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .content-2-swiper {
    margin-top: 33px;
    position: relative;
    background: yellowgreen;

    .content-2-swiper-dot-box {
      position: absolute;
      bottom: 9px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      z-index: 9999;

      &-item {
        width: 11px;
        height: 11px;
        background: #ccc;
        margin-right: 5px;
        border-radius: 50%;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          background: #ff0000;
        }
      }
    }
  }

  .content-3 {
    margin-top: 56px;
    display: flex;
    min-height: 400px;

    .content-3-left {
      width: 938px;
      margin-right: 37px;

      .newsTab-box {
        border-bottom: 2px solid #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .newsTab {
          color: #989898;
          font-size: 18px;
          font-weight: bold;
          width: 210px;
          height: 66px;
          text-align: center;
          line-height: 66px;
          cursor: pointer;
          @media screen and (max-width: 1670px) {
            width: 190px;
          }

          &.active {
            color: #bf0302;
            background: #f8f8f8;
            border-top: 2px solid #d6a7a5;
          }
        }

        .newsTba-more {
          font-size: 14px;
          color: #686868;
          cursor: pointer;
        }
      }
    }

    .content-3-right {
      flex: 1;
    }
  }
  .service-center {
    margin-top: 49px;
    .service-content {
      margin-top: 18px;
      display: flex;
      align-items: center;
      .service-content-list {
        display: grid;
        grid-template-columns: 179px;
        grid-template-rows: repeat(3, 55px);
        gap: 19px;
        margin-right: 30px;
        .content-list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 18px;
          box-sizing: border-box;
          background: #f8f8f8;
          font-size: 18px;
          color: #7d7d7d;
          cursor: pointer;
          font-weight: bold;
          &.active {
            border-bottom: 1px solid #d6a7a5;
            color: #474747;
          }
          img {
            width: 7px;
            height: 15px;
          }
        }
      }
      .content-children-list {
        flex: 1;
        display: grid;
        grid-template-rows: 203px;
        grid-template-columns: repeat(3, 1fr);
        gap: 23px;
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .content-4 {
    display: flex;
    margin-top: 38px;

    .content-4-left {
      width: 1000px;
      margin-right: 40px;

      .video-list-box {
        margin-top: 18px;
        display: flex;
        align-items: center;

        .video-list-item {
          flex: 1;
          height: 280px;
          margin-right: 24px;
          position: relative;
          cursor: pointer;
          @include flex-center();

          .pause-icon {
            width: 48px;
            height: 63px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }

          .video-des {
            position: absolute;
            bottom: 0;
            height: 66px;
            width: 100%;
            background: rgba(4, 3, 3, 0.6);
            //padding: 0 34px;
            box-sizing: border-box;

            @include flex-center();

            span {
              @include ellipsis(420px);
            }

            font-size: 20px;
            color: #fff;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .content-4-right {
      flex: 1;
      display: flex;

      .content-4-right-content {
        width: 100%;
        height: 90px;
        border: 1px solid #e9e7e7;
        @include flex-center;
        font-size: 30px;
        color: #333;
        margin-bottom: 33px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 1670px) {
          font-size: 20px;
        }

        &-icon {
          width: 63px;
          height: 63px;
          margin-right: 15px;
          @media screen and (max-width: 1670px) {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .special-column-box {
    display: flex;
    align-items: center;
    margin-top: 36px;

    .special-column-logo {
      width: 87px;
      height: 87px;
      margin-right: 12px;
    }
  }
  .friendly-link-box {
    margin-top: 30px;
    margin-bottom: 135px;
    .friendly-link-content {
      margin-top: 40px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 12px;
      .link-img {
        width: 100%;
        height: 80px;
        cursor: pointer;
      }
    }
  }

  .news-box {
    .news-box-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 26px 0 16px 0;
      border-bottom: 1px solid #f2f2f2;
      color: #686868;

      .news-title {
        font-size: 16px;
        display: flex;
        align-items: center;
        flex: 1;
        cursor: pointer;

        .news-dot {
          width: 8px;
          height: 8px;
          background: #916640;
          margin-right: 9px;
          border-radius: 50%;
        }

        .news-des {
          @include ellipsis(700px);
        }

        &:hover {
          color: #bf0302;
        }
      }

      .news-time {
        width: 93px;
      }
    }
  }

  .content-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f8f8f8;

    .content-title {
      height: 66px;
      border-bottom: 2px solid #9b4242;
      text-align: center;
      line-height: 66px;
      font-size: 18px;
      color: #7f7f7f;
      font-weight: bold;
    }

    .content-more {
      font-size: 14px;
      color: #686868;
      cursor: pointer;
    }
  }
}
</style>
